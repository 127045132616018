/* MEDIA QUERY */

@media (max-width: 768px) {
  #hamburger {
    display: block;
    border: 0;
    background-color: transparent;
    cursor: pointer;
  }
  #responsive-nav {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .Navbar-brand {
    display: flex;
    justify-content: space-between;
  }

  .Navbar-toggler {
    display: block;
  }

  .Navbar-collapse {
    display: none;
  }

  .Navbar-nav {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }

  .Nav-item {
    margin: 5px 0;
  }

  .Navbar-collapse.show {
    display: block;
  }

  .Navbar {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    justify-content: space-between;
    padding-top: 3%;
    padding-bottom: 3%;
  }

  .links ul {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 0px;
  }

  .logo-div {
    display: flex;
    width: 20%;
  }

  .links {
    width: 100%;
    margin-top: 5%;
  }

  .links ul li {
    list-style-type: none;
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .logsign {
    margin-left: 0;
    margin-top: 6%;
  }
  .logsign ul {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
  }

  .login-link {
    border: 1px solid #000000;
    border-radius: 10px;
    padding: 5px 100px 5px 100px;
    margin-right: 0px;
  }

  .signup {
    border: 1px solid #330031;
    border-radius: 10px;
    padding: 5px 100px 5px 100px;
    box-shadow: 20px 20px 50px 10px #330031 inset;
  }

  /* HOME PAGE */

  .wrapper {
    width: 90%;
    height: fit-content;
    margin: auto;
    border-radius: 20px;
    background-color: #ca26ff;
    margin-top: 10px;
  }

  .wrapperDiv {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    justify-content: space-between;
  }

  .wrapperHeader {
    width: 80%;
    margin-top: 20px;
  }

  .headerPtag {
    font-family: var(--font);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.015em;
    text-align: left;
    height: fit-content;
    width: 90%;
    margin: auto;
    margin-top: 50px;
  }

  .header-image {
    display: none;
  }

  .headerBtn {
    margin-top: 50px;
    text-align: center;
    padding-top: 17px;
  }

  .pbtn {
    margin-top: 2%;
  }

  /* SERVICE */
  .service-wrapper {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .service {
    margin-top: 50px;
  }

  .service-div {
    height: 572px;
    width: 94%;
    margin: auto;
    margin-top: 20px;
  }

  .purple {
    width: 100%;
  }

  .service-btn {
    width: 100%;
  }

  /* SIGNUP */
  .signup-form {
    height: 600px;
    width: 85%;
    margin: auto;
    background-color: var(--purple);
    border-radius: 20px;
    margin-top: 20px;
  }

  .signup-form-div {
    width: 75%;
    height: 600px;
    margin: auto;
    color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .pswd-btn {
    background-color: transparent;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 2%;
    margin-right: 7%;
    border: none;
  }

  /* LOGIN */

  .signin {
    width: 85%;
    height: 98px;
    background-color: var(--purple);
    margin: auto;
    margin-top: 50px;
    border-radius: 20px;
  }

  .login-form {
    height: 400px;
    width: 85%;
    margin: auto;
    border-radius: 20px;
    background-color: var(--purple);
    margin-top: 20px;
  }

  .button {
    width: 60%;
  }

  .login-header {
    width: 70%;
    margin: auto;
  }

  /* FOOTER */
  .footer {
    height: fit-content;
    width: 100%;
    background-color: #330031;
    margin-top: 10px;
    padding-bottom: 20px;
  }

  .footer-footer {
    height: fit-content;
    width: 100%;
    background-color: #330031;
    margin-top: 5px;
    padding-bottom: 20px;
    padding-top: 40px;
  }

  .footerDiv {
    width: 90%;
    margin: auto;
    display: flex;
    color: var(--white);
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
  }

  .line {
    display: none;
  }

  .footer-links {
    margin-top: 32px;
  }

  .footer-anchor {
    text-align: center;
  }

  .footerUL {
    margin-top: 10px;
  }

  .footerUL li {
    text-align: left;
    list-style-type: none;
    text-decoration: underline;
  }

  .sponsors-text {
    width: 100%;
    margin-top: 32px;
  }

  .sponsors-text p {
    text-align: left;
  }

  .company-text {
    display: flex;
    justify-content: space-between;
    margin-top: 22px;
  }

  .wishlist {
    margin-top: 20px;
  }

  .copyright {
    width: 90%;
    margin: auto;
    margin-top: 60px;
  }

  .copyright p {
    text-align: left;
  }

  /* MOVIES */

  .card {
    width: 18rem;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    height: 450px;
    margin-bottom: 30px;
    border-radius: 20px;
  }

  .card-img-top {
    width: 100%;
    height: 250px;
    object-fit: contain;
    border-radius: 20px;
  }

  .releasedate,
  .movie-link {
    text-align: center;
  }

  .pages {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 95%;
    margin: auto;
    margin-top: 20px;
    gap: 0px;
  }

  /* SEARCH */

  .search-form {
    width: 95%;
    margin: auto;
    margin-top: 10%;
    position: relative;
  }

  .search-btn {
    background-color: #330031;
    border: 1px solid transparent;
    color: var(--white);
    padding: 8px 30px 8px 30px;
    border-radius: 10px;
    position: absolute;
    right: 0;
  }

  .search-ul {
    margin-top: 2%;
  }

  /* OVERVIEW */

  .overview-wrapper {
    width: 80%;
    margin: auto;
    margin-top: 7%;
  }

  .overview {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .overview-details {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10%;
  }

  .overview-img {
    height: fit-content;
    width: 100%;
  }

  /* PEOPLE */

  .details {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .details-main {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10%;
  }

  /* series overview */

  .series {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .series-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-top: 5%;
  }

  .series-img-div {
    width: 100%;
  }

  .summary-details {
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 8%;
  }
}
@media only screen and (max-width: 992px) and (min-width: 769px) {
  /* NABVAR */
  #hamburger {
    display: block;
    border: 0;
    background-color: transparent;
    cursor: pointer;
  }
  #responsive-nav {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .Navbar-brand {
    display: flex;
    justify-content: space-between;
  }

  .Navbar-toggler {
    display: block;
  }

  .Navbar-collapse {
    display: none;
  }

  .Navbar-nav {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }

  .Nav-item {
    margin: 5px 0;
  }

  .Navbar-collapse.show {
    display: block;
  }

  .Navbar {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    justify-content: space-between;
    padding-top: 3%;
    padding-bottom: 3%;
  }

  .links ul {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 0px;
  }

  .logo-div {
    display: flex;
    width: 20%;
  }

  .links {
    width: 100%;
    margin-top: 5%;
  }

  .links ul li {
    list-style-type: none;
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .logsign {
    margin-left: 0;
    margin-top: 3%;
  }
  .logsign ul {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 200px;
  }

  .login-link {
    border: 1px solid #000000;
    border-radius: 10px;
    padding: 15px 200px 15px 200px;
    margin-right: 0px;
  }

  .signup {
    border: 1px solid #330031;
    border-radius: 10px;
    padding: 15px 200px 15px 200px;
    box-shadow: 20px 20px 50px 10px transparent inset;
    background-color: #330031;
  }

  .Nav-link {
    text-decoration: none;
    color: var(--black);
    font-family: var(--font);
    font-size: 24px;
    font-weight: 700;
    line-height: 47px;
    letter-spacing: 0em;
  }

  /* SERVICE */

  .service-div {
    height: 572px;
    width: 348px;
    margin-top: 2%;
  }
  .service-header {
    font-family: var(--font);
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0.125em;
    text-align: center;
    margin-bottom: 5%;
  }

  .service-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 20px;
  }

  /* FOOTER */
  .footer {
    height: 778px;
    width: 100%;
    background-color: #330031;
    margin-top: 50px;
  }

  .footer-footer {
    height: 758px;
    width: 100%;
    background-color: #330031;
    margin-top: 5px;
  }

  .line {
    width: 157.34px;
    height: 0px;
    margin-top: 20%;
    border: 2px solid #ffffff;
    transform: rotate(-90deg);
  }

  /* HOMEPAGE */
  .wrapperDiv {
    display: flex;
    width: 90%;
    margin: auto;
    justify-content: space-evenly;
    align-items: flex-start;
    position: relative;
    flex-direction: column;
  }

  .wrapper {
    height: 820px;
    padding-bottom: 5%;
  }

  .headerPtag {
    font-family: var(--font);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.015em;
    color: var(--white);
    height: 200px;
    width: 100%;
    margin-top: 50px;
  }

  .header-image {
    object-fit: cover;
    overflow: hidden;
    margin-top: 0;
  }

  .headerDiv {
    margin-top: 10%;
    height: 350px;
  }

  .headerBtn {
    height: 63px;
    width: 426px;
    border-radius: 0px 40% 0px 40%;
    background-color: var(--purple);
    color: var(--white);
    text-decoration: none;
    padding-top: 17px;
  }

  .pbtn {
    margin-top: 3%;
  }

  /* MOVIES */

  .pages {
    display: flex;
    align-items: center;
    width: 60%;
    margin: auto;
    margin-top: 20px;
    gap: 10;
  }

  .card {
    width: 18rem;
    display: flex;
    justify-content: space-evenly;
    height: 420px;
    margin-bottom: 5%;
    border-radius: 20px;
    /* box-shadow: 20px 20px 50px 15px grey; */
    box-shadow: 20px 20px 50px 10px pink inset;
  }

  .movie-link,
  .releasedate {
    text-align: center;
  }

  /* OVERVIEW */

  .overview {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .overview-img {
    height: 100%;
    width: 80%;
    margin: auto;
  }

  .overview-details {
    width: 90%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5%;
  }

  /* SIGNUP */
  .pswd-btn {
    background-color: transparent;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 2%;
    margin-right: 6%;
    border: none;
  }

  .details {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .overview-img-div {
    width: 90%;
    margin: auto;
  }

  .overview-img {
    width: 100%;
  }

  .details-main {
    width: 80%;
    margin: auto;
    margin-top: 8%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .bio {
    margin-top: 2%;
  }

  /* series overview */

  .series {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .series-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 40%;
    margin-top: 12%;
  }

  .series-img-div {
    width: 100%;
  }
  .series-img {
    width: 100%;
    height: 600px;
    object-fit: contain;
  }

  .summary-details {
    width: 40%;
    text-align: left;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 14%;
  }
}

@media only screen and (max-width: 1025px) and (min-width: 993px) {
  /* NAVBAR */
  .Navbar {
    display: flex;
    width: 90%;
    margin: auto;
    gap: 5%;
    padding-top: 1%;
  }

  .Nav-item {
    list-style-type: none;
    margin-right: 20px;
  }

  .logsign {
    margin-left: 5%;
  }

  .logo-div {
    display: flex;
    width: 15%;
  }

  /* HOMEPAGE */

  .headerPtag {
    font-family: var(--font);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.015em;
    text-align: left;
    color: var(--white);
    height: 420px;
    width: 100%;
    margin-top: 80px;
  }

  .wrapperDiv {
    display: flex;
    width: 90%;
    margin: auto;
    justify-content: space-between;
  }

  .header-image {
    object-fit: cover;
    overflow: hidden;
    margin-top: 20%;
  }

  .headerDiv {
    margin-top: 10%;
    width: 40%;
  }

  .wrapper {
    height: 800px;
  }

  .headerBtn {
    height: 73px;
    width: 100%;
    border-radius: 0px 40% 0px 40%;
    background-color: var(--purple);
    color: var(--white);
    text-decoration: none;
    padding-top: 17px;
    font-size: 24px;
  }

  .wrapperHeader2 {
    font-family: var(--font);
    font-size: 40px;
    font-weight: 700;
    text-align: left;
    width: 493px;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 0%;
    color: var(--white);
    margin-top: 20%;
  }

  /* MOVIES */
  .card {
    width: 15rem;
    display: flex;
    justify-content: space-evenly;
    height: 400px;
  }

  .pages {
    display: flex;
    align-items: center;
    width: 50%;
    margin: auto;
    margin-top: 5%;
    gap: 40px;
  }

  .movie-link,
  .releasedate {
    text-align: center;
  }

  /* FOOTER */

  .footer {
    height: 778px;
    width: 100%;
    background-color: #330031;
    margin-top: 100px;
  }

  .footer-footer {
    height: 758px;
    width: 100%;
    background-color: #330031;
    margin-top: 5px;
  }

  /* OVERVIEW */
  .overview-img {
    height: 500px;
  }

  .overview-wrapper {
    width: 80%;
    margin: auto;
    margin-top: 7%;
  }

  .overview-details {
    width: 50%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5%;
  }

  /* SEARCH */
  .search-ul {
    margin-top: 3%;
    gap: 3%;
  }

  .search-form {
    width: 80%;
    margin: auto;
    margin-top: 7%;
    position: relative;
  }

  /* SIGNUP */
  .pswd-btn {
    background-color: transparent;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 2%;
    margin-right: 5%;
    border: none;
  }

  .details-main {
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  /* series overview */
  .series-wrapper {
    width: 90%;
    margin: auto;
  }

  .series {
    display: flex;
    justify-content: space-between;
  }

  .series-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 30%;
  }

  .series-img-div {
    width: 20%;
  }

  .series-img {
    width: 100%;
    height: 400px;
    object-fit: contain;
  }

  .summary-details {
    width: 40%;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 1026px) {
  /* NAVBAR */
  .Navbar {
    display: flex;
    width: 90%;
    margin: auto;
    gap: 12%;
    padding-top: 3%;
  }

  .Nav-item {
    list-style-type: none;
    margin-right: 25px;
  }

  .logsign {
    margin-left: 15%;
  }

  .logo-div {
    display: flex;
    width: 20%;
  }

  /* HOMEPAGE */
  .headerPtag {
    font-family: var(--font);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.015em;
    text-align: left;
    color: var(--white);
    height: 320px;
    width: 100%;
    margin-top: 80px;
  }

  .wrapper {
    margin-top: 2%;
  }

  .wrapperDiv {
    display: flex;
    width: 90%;
    margin: auto;
    justify-content: space-between;
  }

  .header-image {
    object-fit: cover;
    overflow: hidden;
    margin-top: 20%;
  }

  .headerDiv {
    margin-top: 10%;
    width: 60%;
  }

  .wrapper {
    height: 750px;
  }

  .headerBtn {
    height: 73px;
    width: 100%;
    border-radius: 0px 40% 0px 40%;
    background-color: var(--purple);
    color: var(--white);
    text-decoration: none;
    padding-top: 17px;
    font-size: 24px;
  }

  .pbtn {
    margin-top: 0;
  }

  .wrapperHeader2 {
    font-family: var(--font);
    font-size: 40px;
    font-weight: 700;
    text-align: left;
    width: 493px;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 0%;
    color: var(--white);
    margin-top: 20%;
  }

  /* FOOTER */

  .footer {
    height: 730px;
    width: 100%;
    background-color: #330031;
    margin-top: 100px;
  }

  .footer-footer {
    height: 730px;
    width: 100%;
    background-color: #330031;
    margin-top: 2%;
  }

  /* SEARCH */

  .search-ul {
    margin-top: 3%;
    gap: 3%;
  }

  .search-form {
    width: 80%;
    margin: auto;
    margin-top: 7%;
    position: relative;
  }

  /* SIGNUP */
  .pswd-btn {
    background-color: transparent;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 1%;
    margin-right: 4%;
    border: none;
  }

  /* OVERVIEW */

  .overview-details {
    width: 50%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5%;
  }
  /* PEOPLE SEARCH */
  .details-main {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  /* series overview */
  .series-wrapper {
    width: 90%;
    margin: auto;
  }

  .series {
    display: flex;
    justify-content: space-between;
  }

  .series-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 25%;
  }

  .series-img-div {
    width: 20%;
  }

  .series-img {
    width: 100%;
    height: 400px;
    object-fit: contain;
  }

  .summary-details {
    width: 45%;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}
