* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font);
}

:root {
  --primary-color: #f5f5f5;
  --font: Roboto;
  --white: #ffffff;
  --black: #060606;
  --purple: #330031;
  --pink: #ca26ff;
}

body {
  background-color: var(--primary-color);
}

/* NAVBAR */

#navSection {
  box-shadow: 5px 5px 50px 5px grey;
}

.navbar {
  width: 90%;
  margin: auto;
}

@media (prefers-reduced-motion: no-preference) {
  .logo {
    animation: App-logo-spin infinite 5s linear;
    height: 60px;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#hamburger {
  display: none;
}

#responsive-nav {
  display: flex;
  justify-content: space-between;
}

.logo-div {
  display: flex;
  width: 20%;
}

.logo-text {
  font-family: Segoe UI;
  font-size: 21px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 10px;
}

.Navwrapper {
  width: 100%;
}

.Navbar {
  display: flex;
  width: 90%;
  margin: auto;
  gap: 15%;
  padding-top: 1%;
}

.Navbar-nav {
  margin-top: 10px;
}

.Navbar-nav {
  display: flex;
}

.Nav-item {
  list-style-type: none;
  margin-right: 40px;
}

.Nav-link {
  text-decoration: none;
  color: var(--black);
  font-family: var(--font);
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
}

.Nav-link:hover {
  text-decoration: underline;
  width: 5%;
  color: var(--black);
}

.logsign {
  margin-left: 20%;
}

.Navbar-toggler {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: none;
}

.Navbar-toggler-icon {
  width: 20px;
  height: 2px;
  background-color: black;
  display: block;
  position: relative;
  transition: background-color 0.3s ease;
}

.Navbar-toggler-icon:before,
.Navbar-toggler-icon:after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: black;
  display: block;
  position: absolute;
  transition: transform 0.3s ease;
}

.Navbar-toggler-icon:before {
  top: -6px;
}

.Navbar-toggler-icon:after {
  bottom: -6px;
}

.Navbar-toggler.active .Navbar-toggler-icon {
  background-color: transparent;
}

.Navbar-toggler.active .Navbar-toggler-icon:before {
  transform: rotate(45deg);
  top: 0;
}

.Navbar-toggler.active .Navbar-toggler-icon:after {
  transform: rotate(-45deg);
  bottom: 0;
}

.logsign ul {
  display: flex;
  justify-content: space-evenly;
}

.logsign ul li {
  list-style-type: none;
}

.logsign ul li a {
  text-decoration: none;
  color: var(--black);
  font-family: var(--font);
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
}

.login-link {
  border: 1px solid #000000;
  border-radius: 10px;
  padding: 5px 16px 5px 16px;
  margin-right: 20px;
}

.login-link:hover {
  box-shadow: 20px 20px 50px 10px #330031 inset;
  color: var(--white);
}

.logsign ul li div a {
  color: var(--white);
}

.signup {
  border: 1px solid #330031;
  border-radius: 10px;
  padding: 5px 16px 5px 16px;
  box-shadow: 20px 20px 50px 10px #330031 inset;
}

.signup:hover {
  box-shadow: 20px 20px 50px 10px #fff inset;
  color: var(--black);
}

/* HOMEPAGE */

.wrapper {
  width: 90%;
  height: 641px;
  margin: auto;
  background: linear-gradient(
    135deg,
    #ca26ff 7.17%,
    #f4cac0 85.48%,
    #fff3b0 100%
  );
  border-radius: 20px 20px 300px 20px;
}

.wrapperDiv {
  display: flex;
  width: 90%;
  margin: auto;
  justify-content: space-between;
}

.headerDiv {
  margin-top: 10%;
}

.wrapperHeader {
  width: 319px;
  font-family: var(--font);
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 0px;
  letter-spacing: 0.125em;
  color: #330031;
}

.wrapperHeader2 {
  font-family: var(--font);
  font-size: 40px;
  font-weight: 700;
  text-align: left;
  width: 493px;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 0%;
  color: var(--white);
  margin-top: 68px;
}

/* https://api.themoviedb.org/3/search/movie?api_key=0c8d9eb082bdb49bc2a86e9312bf02df&query=Movie&language=en-US&page=3&year=2022 */

.headerPtag {
  font-family: var(--font);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.015em;
  text-align: left;
  color: var(--white);
  height: 200px;
  width: 650px;
  margin-top: 70px;
}

.header-image {
  object-fit: cover;
  overflow: hidden;

  margin-top: 20%;
}

.headerBtn {
  height: 63px;
  width: 226px;
  border-radius: 0px 40% 0px 40%;
  background-color: var(--purple);
  color: var(--white);
  text-decoration: none;
  padding-top: 17px;
}

.pbtn {
  margin-top: 10%;
}

.headerBtn:hover {
  background-color: var(--purple);
  color: var(--white);
  box-shadow: 5px 5px #fff;
}

/* FOOTER */
.footer {
  height: 678px;
  width: 100%;
  background-color: #330031;
  margin-top: 100px;
}

.footer-footer {
  height: 678px;
  width: 100%;
  background-color: #330031;
  margin-top: 5px;
}

.footerDiv {
  width: 90%;
  margin: auto;
  display: flex;
  color: var(--white);

  justify-content: space-evenly;
}

.footer-links {
  margin-top: 62px;
}

.footer-anchor {
  font-family: var(--font);
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: none;
  color: var(--white);
  margin-right: 0px;
}

.footer-anchor:hover {
  text-decoration: underline;
  color: var(--white);
}

.footer-anchor i {
  margin-right: 24px;
  height: 40.19643211364746px;
  width: 30px;
}

.line {
  width: 257.34px;
  height: 0px;
  margin-top: 20%;
  border: 2px solid #ffffff;
  transform: rotate(-90deg);
}

.line-footer {
  display: flex;
}

.sponsors-text p {
  font-family: var(--font);
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--white);
}

.footerUL {
  margin-top: 70px;
}

.footerUL li {
  text-align: center;
  list-style-type: none;
  text-decoration: underline;
}

.sponsors-text {
  margin-top: 62px;
}

.company-text p {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.company-text {
  display: flex;
  justify-content: space-between;
  margin-top: 62px;
}

.reglog {
  display: flex;
  justify-content: space-between;
}

.products {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.products:nth-child(2) {
  margin-left: 80px;
}

.signup-login {
  color: var(--white);
  text-decoration: none;
}

.signup-login:hover {
  color: var(--white);
  text-decoration: underline;
}

.wishlist {
  margin-top: 50px;
}
.copyright {
  text-align: center;
}

.copyright p {
  font-family: var(--font);
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--white);
}

/* SERVICE */

.service {
  width: 90%;
  margin: auto;
  margin-top: 100px;
}

.service-service {
  width: 90%;
  margin: auto;
  margin-top: 20px;
}

.service-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

.service-div {
  height: 572px;
  width: 348px;
  margin-top: 50px;
}

.purple {
  height: 57px;
  width: 348px;
  background-color: var(--purple);
  border-radius: 20px 20px 0px 0px;
  color: var(--white);
  text-align: center;
  font-family: var(--font);
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.125em;
  padding-top: 12px;
}

.service-btn {
  height: 57px;
  width: 348px;
  background-color: var(--purple);
  border-radius: 0px 0px 20px 20px;
  color: var(--white);
  text-align: center;
  font-family: var(--font);
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.125em;
  border: none;
}

.service-btn:hover {
  background-color: var(--white);
  border: 3px solid #330031;
  color: var(--purple);
}

.white {
  background-color: var(--white);
  height: 415px;
}

.white-text {
  width: 85%;
  margin: auto;
  text-align: left;
  padding-top: 50px;
}

.service-header {
  font-family: var(--font);
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0.125em;
  text-align: center;
}

.service-icon {
  width: 24px;
  height: 24px;
  color: var(--pink);
  margin-left: 10px;
}

.resolutions {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.resolutions p {
  margin-top: 30px;
}

/* LOGIN */
.login {
}

.login-form {
  height: 424px;
  width: 60%;
  margin: auto;
  border-radius: 20px;
  background-color: var(--purple);
  margin-top: 20px;
}

.login-header {
  margin-top: 20px;
}

.login-header h4 {
  font-family: var(--font);
  font-size: 50px;
  font-weight: 700;
  letter-spacing: 0.205em;
  text-align: center;
}

.login-header p {
  font-family: var(--font);
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 0.035em;
  text-align: center;
}

.form-div {
  width: 75%;
  height: 424px;
  margin: auto;
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.forgotpswd {
  text-decoration: none;
}

.forgotpswd:hover {
  text-decoration: underline;
}

.button {
  width: 50%;
}

.signin {
  width: 60%;
  height: 128px;
  background-color: var(--purple);
  margin: auto;
  margin-top: 50px;
  border-radius: 20px;
}

.signin-div {
  width: 75%;
  height: 128px;
  margin: auto;
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: var(--font);
  font-weight: 400;
  letter-spacing: 0.035em;
  text-align: center;
}

/* SIGNUP */

.signup-form {
  height: 600px;
  width: 60%;
  margin: auto;
  background-color: var(--purple);
  border-radius: 20px;
  margin-top: 20px;
}

.signup-form-div {
  width: 75%;
  height: 600px;
  margin: auto;
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pswd {
  position: relative;
}
.pswd-btn {
  background-color: transparent;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 1%;
  margin-right: 3%;
  border: none;
}

/* MOVIES */

.movies {
  width: 90%;
  margin: auto;
  margin-top: 30px;
}

.card-img-top {
  width: 100%;
  height: 200px;
  object-fit: contain;
  border-radius: 20px;
}
.card {
  width: 15rem;
  display: flex;
  justify-content: space-evenly;
  height: 420px;
  margin-bottom: 20px;
  border-radius: 20px;
  /* box-shadow: 20px 20px 50px 15px grey; */
  box-shadow: 20px 20px 50px 10px pink inset;
}

.releasedate {
  text-align: center;
}

.movies ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

.movies ul li {
  list-style-type: none;
}

.movies ul li a {
  text-decoration: none;
  text-align: center;
  color: var(--black);
}

.movies ul li a:hover {
  text-decoration: underline;
  text-align: center;
  color: var(--purple);
}

.pages {
  display: flex;
  align-items: center;
  width: 30%;
  margin: auto;
  margin-top: 20px;
  gap: 40px;
}

#active-page {
  background-color: pink;
  color: var(--black);
  text-decoration: none;
}

#active-page a {
  padding: 7px 16px 7px 16px;
}

#other-page {
  background-color: var(--purple);
  color: var(--white);
  text-decoration: none;
}

#other-page:hover {
  background-color: pink;
  color: var(--black);
  text-decoration: none;
}
#other-page a {
  color: var(--white);
  text-decoration: none;
  padding: 7px 16px 7px 16px;
}

#other-page a:hover {
  background-color: pink;
  color: var(--black);
}

.movie-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.movie-link {
  text-align: center;
}

/* OVERVIEW */

.overview-wrapper {
  width: 70%;
  margin: auto;
  margin-top: 7%;
}

.overview {
  display: flex;
  justify-content: space-between;
}

.overview-img {
  height: 500px;
}

.overview-details {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* SEARCH */
.search-ul {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2%;
  justify-content: space-evenly;
  align-items: center;
}

.search-form-input {
  width: 100%;
  padding: 8px;
  border: 2px solid #330031;
  border-radius: 10px;
  background-color: transparent;
}

.search-wrapper {
  width: 80%;
  margin: auto;
}

.search-form {
  width: 80%;
  margin: auto;
  margin-top: 5%;
  position: relative;
}

.search-btn {
  background-color: #330031;
  border: 1px solid transparent;
  color: var(--white);
  padding: 8px 40px 8px 40px;
  border-radius: 10px;
  position: absolute;
  right: 0;
}

.search-btn:hover {
  border: 2px solid #330031;
  background-color: #ca26ff;
}

/* NOPAGE */
.nopage {
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inner-nopage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.nopage-icon {
  font-size: 48px;
}

/* PEOPLE SEARCH */

.pple_search_li {
  list-style-type: none;
}

.people-wrapper {
  width: 90%;
  margin: auto;
  margin-top: 7%;
}

.people {
  display: flex;
  justify-content: space-between;
}

.people-img-div {
  margin-right: 10%;
}

.people-img {
  height: 500px;
}

.people-details {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.people-movie-link:hover {
  text-decoration: underline;
  cursor: pointer;
}
/* 
ACTORS DETAILS */

.details-wrapper {
  width: 90%;
  margin: auto;
  margin-top: 7%;
}

.details {
  display: flex;
  justify-content: space-between;
}

.overview-img {
  height: 500px;
}

.details-main {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* series overview */
.series-wrapper {
  width: 90%;
  margin: auto;
}

.series {
  display: flex;
  justify-content: space-between;
}

.series-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 20%;
}

.series-img-div {
  width: 20%;
}

.series-img {
  width: 100%;
}

.summary-details {
  width: 50%;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
